import { datadogRum } from '@datadog/browser-rum';
import p from '../../package.json';

declare let CONFIG;

/**
 * Initializes the data dog rum
 */
export const initDataDog = () => {
  const {
    dataDog: { applicationId, clientToken, env },
  } = CONFIG;
  datadogRum.init({
    applicationId,
    clientToken,
    // `site` refers to the Datadog site parameter of your organization see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'digital-sales-bundle',
    env,
    version: p.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};
