import Icon from '@amfament/digitalsales-lib-coreuicomps/Icon';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import DisplayText from '@amfament/digitalsales-lib-coreuicomps/DisplayText';
import { Link } from '@mui/material';
import {
  getRefinedRoute,
  useDisplayHeaderFooter,
  useDisplaySaveForLater,
  useDisplayNeedHelp,
  useHeaderTitle,
} from '../../app/ContextAPI';
import type { ResourceConfig } from '../../app/types';
import HelpMenu from '../HelpMenu';
import useStyles from './useStyles';

declare let CONFIG: ResourceConfig;
const logoSrc = `${CONFIG.image.baseURL}amfam-hex-01-no-margin.png`;
const logoTitle = CONFIG.companyName;
const logoLink = CONFIG.companySite.baseURL;

const Header: React.FC = () => {
  const [helpMenuSelected, setHelpMenuSelected] = useState(false);
  const displayHeaderFooter = useDisplayHeaderFooter();
  const displaySaveForLater = useDisplaySaveForLater();
  const displayNeedHelp = useDisplayNeedHelp();
  const headerTitle = useHeaderTitle();
  const classes = useStyles();
  const needHelpRef = useRef<HTMLDivElement>(null);
  /**
   * On confirmation page it displays print instead of the normal help menu
   */
  const toggleHeaderLink = useCallback(
    () => setHelpMenuSelected((prevState) => !prevState),
    [],
  );

  useEffect(() => {
    // Handles clicking outside help link
    const handleClickOutside = (event: any): void => {
      if (!needHelpRef.current?.contains(event.target)) {
        setHelpMenuSelected(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [needHelpRef]);

  return displayHeaderFooter ? (
    <div css={classes.root}>
      <Grid css={classes.container} alignItems="center" container>
        <Grid item xs>
          <Link href={logoLink} target="_self">
            <img
              css={classes.logo}
              src={logoSrc}
              title={logoTitle}
              alt={logoTitle}
            />
          </Link>
          <Hidden lgDown>
            <div css={classes.titleSection}>
              <DisplayText variant="h3" css={classes.title}>
                {headerTitle}
              </DisplayText>
            </div>
          </Hidden>
        </Grid>
        <Grid item container xs="auto" css={classes.additionalLinksContainer}>
          {displaySaveForLater && (
            <Grid item xs="auto" css={classes.saveForLaterContainer}>
              <NavLink
                to={getRefinedRoute('/core/save-for-later')}
                css={[classes.iconContainer, classes.iconText]}
              >
                Save for Later
              </NavLink>
            </Grid>
          )}
          {displayNeedHelp && (
            <Grid
              item
              xs="auto"
              css={classes.needHelpContainer}
              ref={needHelpRef}
            >
              <div
                css={classes.iconContainer}
                onClick={toggleHeaderLink}
                onKeyPress={toggleHeaderLink}
                role="button"
                tabIndex={0}
              >
                <DisplayText
                  color="primary.main"
                  css={[classes.iconText, classes.disappearIconText]}
                >
                  Need Help
                </DisplayText>
                <Icon icon="help" color="amfamBlue" size="small" />
              </div>

              {helpMenuSelected && (
                <Grid css={classes.helpMenu}>
                  <HelpMenu />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <div css={classes.banner} />
    </div>
  ) : null;
};

export default Header;
